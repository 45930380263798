<template>
    <div class="howItWorks">
        <two-column-container min-height="28rem">
            <template slot="left">
                <div class="max-w-xs mx-auto py-4 text-center">
                    <h3 class="pb-4" v-text="$t('HOMEPAGE.HIW.TITLE')"></h3>
                    <p v-text="$t('HOMEPAGE.HIW.SUMMARY')"></p>
                    <p v-text="$t('HOMEPAGE.HIW.SUMMARY_B')"></p>
                </div>
            </template>
            <template slot="right">
                <div class="flex justify-center">
                    <img class="howItWorks__mockImg"
                         src="@/assets/images/mocks/mockuper-1.png"/>
                </div>
            </template>
        </two-column-container>
    </div>
</template>

<script>
    import TwoColumnContainer from '@/components/structure/TwoColumnContainer';

    export default {
        name      : 'HowItWorks',
        components: {
            TwoColumnContainer,
        },
    };
</script>

<style scoped lang="scss">
    .howItWorks {
        @apply text-white;
        background: #40798C;
        background: linear-gradient(326deg, #79c1d9 0%, #40798c 80%);

        p {
            @apply leading-snug;
        }

        &__mockImg {
            @apply w-full object-contain;
            max-width: 25rem;
            max-height: 25rem;
        }
    }
</style>
