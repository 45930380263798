<template>
    <div class="container mx-auto md:flex items-center px-4 lg:px-0 py-4" :style="styles">
        <div class="w-full md:w-1/2">
            <slot name="left">
                Left content
            </slot>
        </div>
        <div class="w-full md:w-1/2">
            <slot name="right">
                Right content
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'TwoColumnContainer',
        props: {
            minHeight: {
                required: false,
                type    : String,
                default : 'auto',
            },
        },
        computed: {
            styles: function () {
                return `min-height: ${this.minHeight}`;
            }
        }
    };
</script>

<style scoped>

</style>
