<template>
    <div class="howItWorksFree">
        <two-column-container min-height="28rem">
            <template slot="left">
                <div class="max-w-xs mx-auto py-4 text-center">
                    <p v-html="$t('HOMEPAGE.HIW_FREE')"></p>
                    <router-link
                        class="button__btn button__btn--secondary button__btn--fullWidth mt-4"
                        :to="{name: 'auth.register.step-1'}">
                        {{ $t('CORE.GET_STARTED_CTA') }}
                    </router-link>
                </div>
            </template>
            <template slot="right">
                <div class="flex justify-center">
                    <img class="howItWorksFree__mockImg"
                         src="@/assets/images/mocks/iphoneXmockup.png"/>
                </div>
            </template>
        </two-column-container>
    </div>
</template>

<script>
    import TwoColumnContainer from '@/components/structure/TwoColumnContainer';

    export default {
        name      : 'HiwFree',
        components: {
            TwoColumnContainer,
        },
    };
</script>

<style scoped lang="scss">
    .howItWorksFree {
        @apply relative text-white;
        background: #b4d6b3;
        background: linear-gradient(148deg, rgba(180, 214, 179, 1) 0%, rgba(103, 138, 102, 1) 75%);

        p {
            @apply leading-snug;
        }

        &__mockImg {
            @apply absolute bottom-0 object-cover object-top w-2/5 ;
            height: 11rem;
            width: 11rem;

            @screen md {
                @apply relative bottom-auto object-contain my-0 w-auto;

                height: 25rem;
                max-width: 25rem;
                max-height: 25rem;
            }
        }
    }
</style>
