<template>
    <div class="learnLanguageOnline">
        <language-hero
            :title="$t('LANGUAGE_LANDING.ENGLISH.TITLE')"
            :summary="$t('LANGUAGE_LANDING.ENGLISH.SUMMARY')"
            img="https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)"
        />
        <language-courses-overview
            :title="$t('LANGUAGE_LANDING.ENGLISH.COURSE.TITLE')"
            :overview="$t('LANGUAGE_LANDING.ENGLISH.COURSE.OVERVIEW')"
            :courses="courses"
        />
        <how-it-works/>
        <hiw-gamification/>
        <hiw-free/>
    </div>
</template>

<script>
    import HiwFree from '@/components/partials/LanguageLanding/HiwFree';
    import HowItWorks from '@/components/partials/LanguageLanding/HowItWorks';
    import LanguageHero from '@/components/partials/LanguageLanding/LanguageHero';
    import HiwGamification from '@/components/partials/LanguageLanding/HiwGamification';
    import LanguageCoursesOverview from '@/components/partials/LanguageLanding/LanguageCoursesOverview';

    export default {
        name      : 'LearnEnglishOnline',
        metaInfo  : function () {
            return {
                title: this.$t('META.LEARN_LANGUAGE_LANDING.TITLE', {
                    language: this.$t('LANGUAGES.EN'),
                }),
                meta : [
                    {
                        vmid   : 'description',
                        name   : 'description',
                        content: this.$t('META.LEARN_LANGUAGE_LANDING.DESCRIPTION', {
                            language: this.$t('LANGUAGES.EN'),
                        }),
                    },
                ],
            };
        },
        computed  : {
            courses  : function () {
                return [
                    {
                        title  : this.$t('LANGUAGE_LANDING.ENGLISH.COURSES.CRASH.TITLE'),
                        summary: this.$t('LANGUAGE_LANDING.ENGLISH.COURSES.CRASH.SUMMARY'),
                        img    : 'https://images.pexels.com/photos/34134/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    },
                    {
                        title  : this.$t('LANGUAGE_LANDING.ENGLISH.COURSES.FULL.TITLE'),
                        summary: this.$t('LANGUAGE_LANDING.ENGLISH.COURSES.FULL.SUMMARY'),
                        img    : 'https://images.pexels.com/photos/346885/pexels-photo-346885.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    },
                ];
            },
            targetIso: function () {
                return this.$route.meta.iso || 'EN';
            },
        },
        components: {
            HowItWorks,
            HiwGamification,
            HiwFree,
            LanguageCoursesOverview,
            LanguageHero,
        },
        created   : function () {
            if (!this.$store.getters['Account/getUserProfileLoaded']) {
                this.$i18n.locale = this.targetIso.toLowerCase();
                this.$store.commit('Account/updateInterfaceIso', this.targetIso.toUpperCase());
            }
        },
    };
</script>
