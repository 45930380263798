<template>
    <div>
        <div class="hero learnLanguageOnline__hero" :style="styles">
            <div class="hero__container px-4 sm:px-0 container mx-auto flex items-center justify-center">
                <div class="max-w-base z-0">
                    <div class="min-w-lg text-center mx-auto">
                        <p class="hero__title text-shadow-xl" v-text="title"></p>
                    </div>
                    <div class="text-center mx-auto">
                        <p class="hero__text text-shadow-xl" v-text="summary"></p>
                        <router-link class="button__btn button__btn--secondary button__btn--fullWidth"
                                     :to="{name: 'auth.register.step-1'}">
                            {{ $t('CORE.GET_STARTED_CTA') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name    : 'LanguageHero',
        props   : {
            title  : {
                type    : String,
                required: true,
            },
            summary: {
                type    : String,
                default : '',
                required: false,
            },
            img    : {},
        },
        computed: {
            styles: function () {
                return `background-image:url('${this.img}')`;
            },
        },
    };
</script>

