<template>
    <div class="container mx-auto py-6 px-4 lg:px-0">
        <div class="max-w-screen-md mx-auto text-center pb-4">
            <h3 class="py-4" v-text="title"></h3>
            <p class="leading-tight" v-text="overview"></p>
        </div>
        <div class="flex justify-around flex-wrap">
            <div class="courseItem" v-for="(course, index) in courses" :key="index">
                <img class="courseItem__img"
                     :src="course.img"
                     alt="">
                <div class="px-1">
                    <h3 class="courseItem__title" v-text="course.title"></h3>
                    <p class="courseItem__copy" v-text="course.summary"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LanguageCoursesOverview',
        props: {
            title: {
                type: String,
                required: true,
            },
            overview: {
                type: String,
                required: true,
            },
            courses: {
                type: Array,
                required: true,
            }
        }
    };
</script>

<style scoped lang="scss">
    .courseItem {
        @apply text-center max-w-xs bg-white p-4 my-2;

        @screen lg {
            @apply my-0;
        }

        &__img {
            @apply object-cover w-full h-48;
        }

        &__title {
            @apply text-xl py-2;
        }

        &__copy {
            @apply leading-tight;
        }
    }
</style>
