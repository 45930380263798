<template>
    <div class="howItWorksGamification">
        <two-column-container min-height="28rem">
            <template slot="left">
                <div class="flex justify-center">
                    <img class="howItWorksGamification__mockImg"
                         src="@/assets/images/mocks/mockuper-2.png"/>
                </div>
            </template>
            <template slot="right">
                <div class="max-w-xs mx-auto py-4 text-center">
                    <p v-text="$t('HOMEPAGE.HIW_GAME.SUMMARY')"></p>
                    <p v-html="$t('HOMEPAGE.HIW_GAME.SUMMARY_B')"></p>
                </div>
            </template>
        </two-column-container>
    </div>
</template>

<script>
    import TwoColumnContainer from '@/components/structure/TwoColumnContainer';

    export default {
        name      : 'HiwGamification',
        components: {
            TwoColumnContainer,
        },
    };
</script>

<style scoped lang="scss">
    .howItWorksGamification {
        @apply text-white;
        background: #ed695a;
        background: linear-gradient(217deg, #a1392d 0%, #ed695a 70%);

        p {
            @apply leading-snug;
        }

        &__mockImg {
            @apply w-full h-auto object-contain;
            max-height: 18.75rem;
            max-width: 25rem;
        }
    }
</style>
